import React, {useEffect, useState} from "react"
import {graphql, Link, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image";


const IndexPage = () => {

    const data = useStaticQuery(graphql`
        query {
          main_access: file(relativePath: { eq: "main-access.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 411, maxHeight: 360) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
          main_experiences: file(relativePath: { eq: "main-experiences.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 411, maxHeight: 360) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
        }
      `);

      let cellHeight = useWindowSize();
      let style = cellHeight ? {height: cellHeight + 'px'} : null;

    return (
        <Layout>
            <SEO title=""/>

            <div className="container">
                <h1>Experience life</h1>
            </div>

            <div id="elfsight-main" className="elfsight-app-d0c4ec03-304a-4baf-a718-91920176abf4" style={style}>
              <div id="elfsight-loading" className="browser-screen-loading-content" style={style}>
                <div className="loading-dots"/>
              </div>
            </div>

            <div className="container">
                <h2>Services</h2>

                <div className="row">
                    <div className="half-block">
                        <Img objectFit="cover" fluid={data.main_access.childImageSharp.fluid} className="image"/>
                        <h3>Access</h3>
                        <p>
                            Boasting exclusive partnerships with a curated network of companies and
                            providers, Lux Delux offers its members unmatched access to the very best the
                            world has to offer. As your personal luxury lifestyle concierge, we’ll navigate
                            gatekeepers and tastemakers to ensure that when the velvet rope parts, all you
                            have to do is step inside.
                        </p>
                    </div>

                    <div className="half-block">
                        <Img objectFit="cover" fluid={data.main_experiences.childImageSharp.fluid} className="image"/>
                        <h3>Experiences</h3>
                        <p>
                            From dining, nightlife, and entertainment to luxury getaways and one-of-a-kind
                            bespoke experiences, Lux Delux is your all-access pass to the best things in
                            life. Whether you’re after exclusive entry to members-only galas or something
                            custom tailored to your personal preferences, Lux Delux is your ticket to the
                            very best that life has to offer—from the everyday to the exceptional.
                        </p>
                    </div>
                </div>

                <p className="more">
                    <Link to="/services" className="link">View all services</Link>
                </p>

            </div>
        </Layout>
    )
};

// Hook
function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {

      let width =  isClient ? window.innerWidth : undefined;
      let column = 5;

      if(width <= 1112) column = 4;
      if(width <= 768) column = 2;
      if(width <= 480) column = 1;

      return width / column;
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    function initializeVariables(){
        if(document){
            const eM = document.getElementById('elfsight-main');
            const eL = document.getElementById('elfsight-loading');

            let height = getSize();

            eM.style.height = height + 'px';
            eL.style.height = height + 'px';
        }
    }

    window.addEventListener('resize', handleResize);
    initializeVariables();

    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export default IndexPage
